<template>
    <div>
        <CommonTabsComponent :tabList="toolTabs" @tabChanged="tabChanged">
        </CommonTabsComponent>
        <component
            v-bind:is="componentName"
            @navigateToDataTool="navigateToDataTool"
        />
    </div>
</template>

<script>
import { getComponentName } from '../../utilities/oneMapTools.js';
export default {
    components: {
        CommonTabsComponent: () =>
            import('../CommonComponents/CommonTabsComponent.vue'),
        SavedFilters: () => import('./SavedFilters.vue'),
        SavedHistory: () => import('./SavedHistory.vue'),
    },
    data() {
        return {
            toolTabs: [{ name: 'Saved filters' }, { name: 'History' }],
            displayedToolTab: 'Saved filters',
        };
    },
    methods: {
        tabChanged(newTab) {
            this.displayedToolTab = newTab.name;
        },
        navigateToDataTool() {
            this.$emit('navigateToDataTool');
        },
    },
    computed: {
        componentName() {
            return this.displayedToolTab
                ? getComponentName(this.displayedToolTab)
                : '';
        },
    },
};
</script>

<style></style>
